<template>
    <div>
        <common-header>保險</common-header>
        <div class="rb-img">
            <img src="../../assets/images/rb.png" alt="">
        </div>
        <div class="bx-container">
            <ul>
                <li @click="toBxDetail(item.id)" v-for="item in bxList" :key="item.title" class="item">
                    <div class="item-img"><img :src="item.imgSrc" alt=""></div>
                    <div class="text">
                        <h3>{{item.title}}</h3>
                        <span class="desc" v-html="item.content"></span>
                    </div>
                </li>
            </ul>
            <!-- <div class="desc">由中国人民财产保险股份有限公司福建省分公司承保</div> -->
        </div>
    </div>
</template>
<script>
import CommonHeader from '@/components/header'
export default {
    data() {
        return {
            bxList: [{
                id: 1,
                imgSrc: require('@/assets/images/bx-1.png'),
                title: '人人安康百萬醫療保險',
                content: `壹般醫療費用補償<br />惡性腫瘤醫療費用補償`,
            }, {
                id: 2,
                imgSrc: require('@/assets/images/bx-2.png'),
                title: '預防接種意外傷害保險',
                content: `預防接種異常反應意外醫療費用補償<br />
                            預防接種事故意外醫療費用補償<br />
                            預防接種異常失效身故給付`
            }, {
                id: 3,
                imgSrc: require('@/assets/images/bx-3.png'),
                title: '意外傷害保險',
                content: `意外身故、殘疾給付<br />
                    意外醫療費用補償<br />
                    意外住院津貼`
            }]
        }
    },
    components: {
        CommonHeader
    },
    methods: {
        toBxDetail(id) {
            this.$router.push(`/bxDetail?id=${id}`)
        }
    }
}
</script>
<style lang="scss" scoped>
.rb-img {
    width: 100%;
    height: 38px;
    padding-top: 44px;
    img {
        width: 100%;
        height: 100%;
    }
}
.bx-container {
    padding: 20px;
    box-sizing: border-box;
    min-height: 100vh;
    background: #F2F2F2;

    .item {
        text-align: left;
        margin-bottom: 20px;
    }

    .text {
        padding: 0 0 14px 17px;
        background: #fff;
        border-radius: 0 0 16px 16px;
        margin-top: -5px;

        h3 {
            font-size: 20px;
            color: #333;
            height: 40px;
            line-height: 40px;
        }
    }

    .desc {
        font-size: 14px;
    }

    .item-img {
        width: 100%;

        img {
            width: 100%;
        }
    }
    .desc {
        font-size: 14px;
        color: #666;
    }
}
</style>